'use strict';

Gri.module({
  name: 'results-alpha',
  ieVersion: null,
  $el: $('.results-alpha'),
  container: '.results-alpha',
  fn: function () {

    const $this = this.$el;
    let $search = $('.search-alpha');
    let $searchContent = $('.search-alpha-content');

    $search.outerHeight($(window).height());
    $this.outerHeight($search.height() - $searchContent.outerHeight() - 50);

  }
});
